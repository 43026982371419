import React, { Component } from 'react';
import PropTypes from "prop-types"
import "./style.css"
import "../../styles/form.css"


class ContactForm extends Component {
	state = {
		name: '',
		location: '',
		mobile: '',
		email: '',
		message: '',
		errormsg: null,
		formready: true
	}

	onNameChange(event) {
		if (this.props.showMessage === false) {
			this.setState({
					errormsg: null,
					name: event.target.value,
					message: "Locate a Dealer Submission",
				})
		} else {
			this.setState({errormsg: null, name: event.target.value})
		}
	}

	onLocationChange(event) {
		this.setState({errormsg: null, location: event.target.value})
	}

	onMobileChange(event) {
		this.setState({errormsg: null, mobile: event.target.value})
	}

	onEmailChange(event) {
		this.setState({errormsg: null, email: event.target.value})
	}

	onMessageChange(event) {
		this.setState({errormsg: null, message: event.target.value})
	}

	handleSubmit(event) {
		var apisuburl = "contact";
		if (this.props.showMessage === false) {
			apisuburl = "locate";
		}

		event.preventDefault();
		if (this.state.name.length < 1) {
			this.setState({errormsg: "Please specify name"})
			return;
		} else if (this.state.location.length < 1) {
			this.setState({errormsg: "Please specify location"})
			return;
		} else if (this.state.mobile.length < 1) {
			this.setState({errormsg: "Please specify contact number"})
			return;
		} else if (this.state.email.length < 1) {
			this.setState({errormsg: "Please specify email"})
			return;
		} else if (this.state.message.length < 1 && this.props.showMessage === true) {
			this.setState({errormsg: "Please specify message"})
			return;
		} else {
			// Validate email and number
			var regexstr = /^[\w\-.+]+@[a-zA-Z0-9.-]+\.[a-zA-z0-9]{2,4}$/;
			if(!this.state.email.match(regexstr)){
				this.setState({errormsg: "Invalid email"})
				return;
			}
			regexstr = /^(\+)?([0-9]\d{10,14})$/;
			if(!this.state.mobile.match(regexstr)){
				this.setState({errormsg: "Invalid contact number"})
				return;
			}
		}
		this.setState({
			errormsg: "Please wait...",
			formready: false
		});
		//console.log(this.state);
		fetch('https://api.boysencdn.com/contact/konstrukt/'+apisuburl,{
				method: "POST",
				body: JSON.stringify({
					formname: this.state.name,
					formemail: this.state.email,
					formphone: this.state.mobile,
					formmsg: this.state.message,
					formlocation: this.state.location
				}),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(
				(response) => (response.json())
			).then((response)=>{
				//console.log(response);
				if (response === "Send OK") {
					this.handleClear();
					this.setState({
						errormsg: "Message sent, thank you!",
						formready: true
					});
				} else {
					this.setState({
						errormsg: "Error Sending Message",
						formready: true
					});
				}
			});
	}

	handleClear = () => {
		this.setState(state => ({
			name: '',
			mobile: '',
			email: '',
			message: '',
			location: '',
			errormsg: null
		}));
	}

	render() {
		return(
			<form className={this.props.mobileDisplay=== false? "contactform contactformdesktop": "contactform fixedmobile contactformmobile"} onSubmit={this.handleSubmit.bind(this)} method="POST">
				<div className="contactformfield">
					{this.props.showLabel ? <label htmlFor="contactname">Name</label> : ""}
					<input placeholder={!this.props.showLabel?"NAME":""}
						type="text" value={this.state.name} onChange={this.onNameChange.bind(this)} />
				</div>
				<div className="contactformfield">
					{this.props.showLabel ? <label htmlFor="contactname">Location</label> : ""}
					<input placeholder={!this.props.showLabel?"LOCATION":""}
						type="text" value={this.state.location} onChange={this.onLocationChange.bind(this)} />
				</div>
				<div className="contactformfield">
					{this.props.showLabel ? <label htmlFor="contactmobile">Contact number</label> : ""}
					<input placeholder={!this.props.showLabel?"CONTACT NUMBER":""}
						type="tel" value={this.state.mobile} onChange={this.onMobileChange.bind(this)} />
				</div>
				<div className="contactformfield">
					{this.props.showLabel ? <label htmlFor="contactemail">E-mail</label> : ""}
					<input placeholder={!this.props.showLabel?"E-MAIL":""}
						type="email" value={this.state.email} onChange={this.onEmailChange.bind(this)} />
				</div>
				{
					this.props.showMessage &&
					<div className="contactformfield">
						{this.props.showLabel ? <label htmlFor="contactmessage">Message</label> : ""}
						<textarea placeholder={!this.props.showLabel?"MESSAGE":""}
							rows="5" value={this.state.message} onChange={this.onMessageChange.bind(this)} />
					</div>
				}
				{
					this.state.formready &&
					<div className="contactformbutton">
						<button className="form-button color-background-secondary font-style-bold" onClick={this.handleClear}>
							Clear
						</button>
						<button className="form-button color-background-primary font-style-bold" type="submit">
							Submit
						</button>
					</div>
				}
				{
					this.state.errormsg &&
					<div className="contactformerror">
						{this.state.errormsg}
					</div>
				}
			</form>
		);
	}
}


ContactForm.propTypes = {
	showMessage: PropTypes.bool,
	showLabel: PropTypes.bool,
	mobileDisplay: PropTypes.bool
};

ContactForm.defaultProps = {
	showMessage: true,
	showLabel: true,
	mobileDisplay: false
};

export default ContactForm;

